import { Plan, AiPlan } from 'src/types/pricing.types';
const assetsUrl = process.env.NEXT_PUBLIC_SHARED_CDN_URL;

const featureWordsList = [
  '120 iStock Media',
  '1M\\+',
  'Unlimited',
  '1080p',
  '10 GB',
  'Reseller',
  '15min',
  'Full Access',
  'All-access',
  'Standard',
  '100\\+',
  '60',
  '10 iStock',
  '10GB',
  // '5000\\+',
  '20 ',
  '40 ',
  '120 iStock',
  '4000\\+',
  'Create and save',
  'Upload',
];

const featuresRegex = new RegExp('^(' + featureWordsList.join('|') + ')');

export const getRichFeatureText = (
  feature: string,
): { text: string; prefix?: string } => {
  const splitFeature = feature.split(featuresRegex);
  if (splitFeature.length === 3) {
    return {
      prefix: splitFeature[1],
      text: splitFeature[2],
    };
  } else {
    return { text: feature };
  }
};

export const INITIAL_PLANS: Plan[] = [
  {
    name: 'FREE',
    description: 'For anyone who wishes to try InVideo',
    planPrice: {
      monthly: {
        currency: '$',
        price: 0,
        subText: '',
      },
      annually: {
        currency: '$',
        price: 0,
        subText: '',
      },
    },
    CTA: {
      monthly: {
        text: 'Start now',
        url: `/workflow/marketing-templates`,
        backgroundColorClass: 'iv-bg-grey-100',
        textColorClass: 'iv-text-10',
      },
      annually: {
        text: 'Start now',
        url: `/workflow/marketing-templates`,
        backgroundColorClass: 'iv-bg-grey-100',
        textColorClass: 'iv-text-10',
      },
    },
    feature: [],
    featuresCheckImage: ``,
    redirection: {
      url: `/workflow/marketing-templates`,
      type: 'internal',
      modal: true,
    },
    currency_symbol: '$',
    features: [],
    id: 1,
    key_features: [],
    label: 'Free',
    rank: 0,
    tagline: '',
    durations: [],
  },
  {
    name: 'BUSINESS',
    description: 'For small business, educators and influencers',
    bannerText: 'Popular',
    planPrice: {
      monthly: {
        currency: '$',
        price: 30,
        subText: '/ mo*',
        starText: '*Billed monthly until cancelled',
      },
      annually: {
        currency: '$',
        price: 15,
        subText: '/ mo*',
        starText: '*Billed $180 annually',
      },
    },
    CTA: {
      monthly: {
        text: 'Get Business',
        url: `/pricing`,
        backgroundColorClass: 'iv-bg-blue',
        textColorClass: 'iv-text-blue',
      },
      annually: {
        text: 'Get Business',
        url: `/pricing`,
        backgroundColorClass: 'iv-bg-blue',
        textColorClass: 'iv-text-blue',
      },
    },
    feature: [],
    appPromo: {
      firstLine: 'Get Filmr Pro ',
      secondLine: 'Mobile app',
      CTAText: 'Read more',
      backgroundGradientClass: 'iv-gradient-raspberry-deep-blue-blue',
      appImage: ``,
    },
    featuresCheckImage: ``,
    redirection: {
      url: `/pricing`,
      type: 'internal',
      modal: true,
    },
    currency_symbol: '$',
    features: [],
    id: 1,
    key_features: [],
    label: 'Business',
    rank: 0,
    tagline: '',
    durations: [],
  },
  {
    name: 'UNLIMITED',
    description: 'For agencies and multiple teams',
    planPrice: {
      monthly: {
        currency: '$',
        price: 60,
        subText: '/ mo*',
        starText: '*Billed monthly until cancelled',
      },
      annually: {
        currency: '$',
        price: 30,
        subText: '/ mo*',
        starText: '*Billed $360 annually',
      },
    },
    CTA: {
      monthly: {
        text: 'Get Unlimited',
        url: `/pricing`,
        backgroundColorClass: 'iv-bg-violet',
        textColorClass: 'iv-text-violet',
      },
      annually: {
        text: 'Get Unlimited',
        url: `/pricing`,
        backgroundColorClass: 'iv-bg-violet',
        textColorClass: 'iv-text-violet',
      },
    },
    feature: [],
    appPromo: {
      firstLine: 'Get Filmr Pro ',
      secondLine: 'Mobile app',
      CTAText: 'Read more',
      backgroundGradientClass: 'iv-gradient-raspberry-violet',
      appImage: ``,
    },
    featuresCheckImage: ``,
    redirection: {
      url: `/pricing`,
      type: 'internal',
      modal: true,
    },
    currency_symbol: '$',
    features: [],
    id: 1,
    key_features: [],
    label: 'Unlimited',
    rank: 0,
    tagline: '',
    durations: [],
  },
];

export const AI_INITIAL_PLANS: AiPlan[] = [
  {
    name: 'Free',
    key: 'free',
    sequence: 1,
    description: {
      desktop: 'For anyone who wishes to try AI video creation',
      mobile: 'Try AI video creation',
    },
    planPrice: {
      monthly: {
        currency: '$',
        price: 0,
        planId: 'free_monthly_usd',
      },
      yearly: {
        currency: '$',
        price: 0,
        planId: 'free_yearly_usd',
      },
    },
    CTA: {
      text: 'Try for free',
      url: `/workflow/marketing-templates`,
      backgroundColorClass: 'iv-bg-grey-100',
      textColorClass: 'iv-text-10',
    },
    redirection: {
      url: `/workflow/marketing-templates`,
      type: 'internal',
      modal: true,
    },
    currency_symbol: '$',
    key_features: [
      {
        icon: `${assetsUrl}/pricing/tick.svg`,
        text: '4 exports/wk with invideo logo',
        quota: 4,
        name: 'exports',
      },
      {
        icon: `${assetsUrl}/pricing/tick.svg`,
        text: 'Premium AI 10 mins/wk',
        quota: 8,
        name: 'premium_generation_minutes',
        help: 'Premium generations come with advanced AI voiceovers & iStock media',
      },
      {
        icon: `${assetsUrl}/pricing/tick.svg`,
        quota: 10,
        name: 'storage',
        text: 'Storage 10 GB',
      },
      {
        icon: `${assetsUrl}/pricing/cross.svg`,
        text: 'iStock',
        quota: 0,
        name: 'istock',
        style: 'iv-line-through',
      },
      {
        icon: `${assetsUrl}/pricing/tick.svg`,
        text: '2.5M+ standard media',
        quota: 0,
        name: 'unlimited_standard_stock',
        style: '',
      },
    ],
  },

  {
    name: 'Plus',
    key: 'plus',
    sequence: 3,
    description: {
      desktop: 'For creators starting their journey',
      mobile: 'Creators starting their journey ',
    },

    planPrice: {
      monthly: {
        currency: '$',
        price: 0,
        planId: 'plus_monthly_usd',
      },
      yearly: {
        currency: '$',
        price: 0,
        planId: 'plus_yearly_usd',
      },
    },
    CTA: {
      text: 'Get Plus',
      url: `/pricing`,
      backgroundColorClass: 'iv-bg-blue',
      textColorClass: 'iv-text-blue',
    },

    redirection: {
      url: `/pricing`,
      type: 'internal',
      modal: true,
    },
    currency_symbol: '$',
    key_features: [
      {
        icon: `${assetsUrl}/pricing/tick.svg`,
        text: '60 exports/mo',
        quota: 60,
        name: 'exports',
      },
      {
        icon: `${assetsUrl}/pricing/tick.svg`,
        text: 'Premium AI 50 mins/mo',
        quota: 50,
        name: 'premium_generation_minutes',
        help: 'Premium generations come with advanced AI voiceovers & iStock media',
      },
      {
        icon: `${assetsUrl}/pricing/tick.svg`,
        quota: 100,
        name: 'storage',
        text: 'Storage 100 GB',
      },
      {
        icon: `${assetsUrl}/pricing/tick.svg`,
        quota: 40,
        name: 'istock',
        text: 'iStock quota 40/mo',
      },
    ],
  },
  {
    name: 'Max',
    key: 'max',
    sequence: 2,
    description: {
      desktop: 'For growing & established creators',
      mobile: 'For growing & established creators',
    },
    planPrice: {
      monthly: {
        currency: '$',
        price: 0,
        planId: 'max_monthly_usd',
      },
      yearly: {
        currency: '$',
        price: 0,
        planId: 'max_yearly_usd',
      },
    },
    CTA: {
      text: 'Get Max',
      url: `/pricing`,
      backgroundColorClass: 'iv-bg-violet',
      textColorClass: 'iv-text-violet',
    },
    redirection: {
      url: `/pricing`,
      type: 'internal',
      modal: true,
    },
    currency_symbol: '$',
    key_features: [
      {
        icon: `${assetsUrl}/pricing/tick.svg`,
        quota: 99999,
        text: 'Unlimited exports',
        name: 'exports',
      },
      {
        icon: `${assetsUrl}/pricing/tick.svg`,
        text: 'Premium AI 200 mins/mo',
        quota: 200,
        name: 'premium_generation_minutes',
        help: 'Premium generations come with advanced AI voiceovers & iStock media',
      },
      {
        icon: `${assetsUrl}/pricing/tick.svg`,
        quota: 400,
        name: 'storage',
        text: 'Storage 400 GB',
      },
      {
        icon: `${assetsUrl}/pricing/tick.svg`,
        quota: 320,
        name: 'istock',
        text: 'iStock quota 160/mo',
      },
    ],
  },
];

export const PLANS_SPECIAL_FEATURES = [
  {
    key: 'video-edit',
    title: 'Free video editor',
    icon: '/pricing/video-edit.svg',
    aiBadge: false,
  },
  {
    key: 'first-cut',
    title: 'AI generated first-cuts',
    icon: '/pricing/first-cut.svg',
    aiBadge: true,
  },
  {
    key: 'stock',
    title: '16mn+ stock media',
    icon: '/pricing/stock.svg',
    aiBadge: false,
  },
  {
    key: 'voiceover',
    title: 'Realistic AI voiceovers',
    icon: '/pricing/voiceover.svg',
    aiBadge: true,
  },
];

export const PLANS_FEATURES_ORDER = [
  'exports',
  'premium_generation_minutes',
  'storage',
  'istock',
  'unlimited_standard_stock',
  'video_resolution',
  'user_data',
  'voice',
];

export const PLANS_FEATURES_DISPLAY_NAME: { [key: string]: string } = {
  exports: 'Exports',
  premium_generation_minutes: 'Premium generation',
  storage: 'Storage',
  istock: 'iStock quota',
  unlimited_standard_stock: 'Unlimited standard stock',
  free_editor: 'Free editor',
  video_resolution: 'Video resolution',
  user_data: 'User data',
  voice: 'Voice Clones',
};

export const CHECKOUT_RELATIVE_URL = '/payments/checkout';
