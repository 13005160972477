import { AxiosResponse } from 'axios';
import {
  InviteResponseType,
  TemplateResponseType,
} from '@components/workflow/types';

import {
  ConfigType,
  CreatePageResponseType,
  HomepageResponseType,
  MakePageResponseType,
  MakePageV3ResponseType,
  MakeRootV2ResponseType,
  PageResponseType,
} from '../../types';
import { CMSDimensionType, DimensionType } from '../../constants/common';

export const ApiModule = () =>
  import('@invideoio/web-shared/lib/API').then(module => module.API);

async function fetchTemplates(
  templateIds: number[],
  dimension: CMSDimensionType[] = [],
) {
  const templatesFetchWithIds = `${process.env.NEXT_PUBLIC_API_URL}/v3/get_seo_template_by_ids`;
  const API = await ApiModule();
  const response: AxiosResponse<TemplateResponseType[]> = await API.post(
    templatesFetchWithIds,
    {
      ids: templateIds,
    },
  );
  const { data } = response;

  let templateList: TemplateResponseType[] = [];
  templateIds.forEach((id, idx) => {
    const d = data.find(e => e.template_id === id);
    if (d) {
      templateList.push(d);
    } else {
      const templateDimension =
        DimensionType[
          idx < dimension.length ? dimension[idx] : CMSDimensionType._1_1
        ];
      templateList.push({
        template_id: id,
        name: '',
        preview_list: {
          story: {
            [templateDimension]: {
              preview_url: '',
              thumbnail_url: '',
              website_thumbnail_url: '',
            },
          },
        },
      });
    }
  });

  return templateList.map((template, idx) => {
    const templateDimension =
      DimensionType[
        idx < dimension.length ? dimension[idx] : CMSDimensionType._1_1
      ];
    const { template_id, name, preview_list } = template;
    const { story } = preview_list;
    const { [templateDimension]: t = { preview_url: '', thumbnail_url: '' } } =
      story;

    const { preview_url, thumbnail_url } = t;

    return {
      id: template_id,
      name,
      previewURL: preview_url,
      thumbnailURL: thumbnail_url,
    };
  });
}

async function fetchConfig() {
  const configURL = `${process.env.NEXT_PUBLIC_CONFIG_URL}`;
  const API = await ApiModule();
  const response: AxiosResponse<ConfigType> = await API.get(configURL);
  const { data: config } = response;

  return config;
}

async function getInvite(paramToken?: string) {
  try {
    let inviteResponse: AxiosResponse<InviteResponseType>;
    const TeamSetting = await import(
      '@invideoio/web-shared/lib/API/team-setting'
    ).then(module => module.TeamSetting);
    inviteResponse = await TeamSetting.fetchInviteData(paramToken);

    if (inviteResponse) {
      const { data, error_code, message } = inviteResponse.data;
      const {
        name,
        role,
        inviter,
        invite_link,
        email_id,
        status,
        intent,
        metadata,
      } = data;
      return {
        name,
        role,
        inviter,
        invite_link,
        email_id,
        status,
        error_code,
        message,
        intent,
        metadata,
      };
    }
  } catch (error) {
    console.log(error);
  }

  return;
}

async function getHomepage(url = '/homepage', locale = 'en') {
  try {
    const API = await ApiModule();
    const response: AxiosResponse<HomepageResponseType> = await API.get(
      `${process.env.NEXT_PUBLIC_STRAPI_API_URL}${url}?_locale=${locale}`,
    );

    return response.data;
  } catch (errors) {
    console.error(errors);
    throw new Error('Failed to fetch API');
  }
}

export async function getHomepageV2(url = '/homepage-v-2', locale = 'en') {
  try {
    const API = await ApiModule();
    const response: AxiosResponse<HomepageResponseType> = await API.get(
      `${process.env.NEXT_PUBLIC_STRAPI_API_URL}${url}?_locale=${locale}`,
    );

    return response.data;
  } catch (errors) {
    console.error(errors);
    throw new Error('Failed to fetch API');
  }
}

export async function getHomepagePreview(url = '/homepage-v-2', locale = 'en') {
  try {
    const API = await ApiModule();
    const response: AxiosResponse<HomepageResponseType> = await API.get(
      `${process.env.NEXT_PUBLIC_STRAPI_API_URL}${url}?_locale=${locale}&_publicationState=preview`,
    );

    return response.data;
  } catch (errors) {
    console.error(errors);
    throw new Error('Failed to fetch API');
  }
}

export async function getHomepageGen3(url = '/homepage-gen3', locale = 'en') {
  try {
    const API = await ApiModule();
    const response: AxiosResponse<any> = await API.get(
      `${process.env.NEXT_PUBLIC_STRAPI_API_URL}${url}?_locale=${locale}`,
    );

    return response.data;
  } catch (errors) {
    console.error(errors);
    throw new Error('Failed to fetch API');
  }
}

export async function getPricingPageGen3(url = '/ai-pricing', locale = 'en') {
  try {
    const API = await ApiModule();
    const response: AxiosResponse<any> = await API.get(
      `${process.env.NEXT_PUBLIC_STRAPI_API_URL}${url}`,
    );

    return response.data;
  } catch (errors) {
    console.error(errors);
    throw new Error('Failed to fetch API');
  }
}

async function getPage(url = '/pages', slug: string, locale = 'en') {
  const API = await ApiModule();
  const response: AxiosResponse<PageResponseType[]> = await API.get(
    `${process.env.NEXT_PUBLIC_STRAPI_API_URL}${url}?Slug=${slug}`,
  );

  if (!!response?.data && response.data.length > 0) {
    return response.data[0];
  } else {
    throw new Error('No data found on API');
  }
}

export async function getMakeRootV2Page(url = '/make-root-v-2', locale = 'en') {
  try {
    const API = await ApiModule();
    const response: AxiosResponse<MakeRootV2ResponseType> = await API.get(
      `${process.env.NEXT_PUBLIC_STRAPI_API_URL}${url}?_locale=${locale}`,
    );
    return response.data;
  } catch (errors) {
    console.error(errors);
    throw new Error('Failed to fetch API');
  }
}

async function getMakePage(slug: string, locale = 'en') {
  const API = await ApiModule();
  const response: AxiosResponse<MakePageResponseType[]> = await API.get(
    `${process.env.NEXT_PUBLIC_STRAPI_API_URL}${process.env.NEXT_PUBLIC_MAKE_PAGE_CMS_ENDPOINT}?Slug_eq=${slug}&_locale=${locale}`,
  );

  if (!!response?.data && response.data.length > 0) {
    return response.data[0];
  } else {
    throw new Error('No data found on API');
  }
}

export async function getMakePageV3(slug: string, locale = 'en') {
  const API = await ApiModule();
  const response: AxiosResponse<MakePageV3ResponseType[]> = await API.get(
    `${process.env.NEXT_PUBLIC_STRAPI_API_URL}/make-v-3-s?Slug_eq=${slug}&_locale=${locale}`,
  );

  if (!!response?.data && response.data.length > 0) {
    return response.data[0];
  } else {
    throw new Error('No data found on API');
  }
}

export async function getCreatePage(slug: string, locale = 'en') {
  const API = await ApiModule();
  const response: AxiosResponse<CreatePageResponseType[]> = await API.get(
    `${process.env.NEXT_PUBLIC_STRAPI_API_URL}/creates?Slug_eq=${slug}&_locale=${locale}`,
  );

  if (!!response?.data && response.data.length > 0) {
    return response.data[0];
  } else {
    throw new Error('No data found on API');
  }
}

export async function getAiPage(slug: string, locale = 'en') {
  const API = await ApiModule();
  const response: AxiosResponse<MakePageResponseType[]> = await API.get(
    `${process.env.NEXT_PUBLIC_STRAPI_API_URL}/ais?Slug_eq=${slug}&_locale=${locale}`,
  );

  if (!!response?.data && response.data.length > 0) {
    return response.data[0];
  } else {
    throw new Error('No data found on API');
  }
}

async function getMakePageById(id: string) {
  try {
    const API = await ApiModule();
    const response: AxiosResponse<MakePageResponseType[]> = await API.get(
      `${process.env.NEXT_PUBLIC_STRAPI_API_URL}${process.env.NEXT_PUBLIC_MAKE_PAGE_CMS_ENDPOINT}/?id_eq=${id}&_publicationState=preview`,
    );

    if (!!response?.data && response.data.length > 0) {
      return response.data[0];
    } else {
      throw new Error('No data found on API');
    }
  } catch (errors) {
    console.error(errors);
    throw new Error('Failed to fetch API');
  }
}

export async function getMakeRootV2PagePreview(
  url = '/make-root-v-2',
  locale = 'en',
) {
  try {
    const API = await ApiModule();
    const response: AxiosResponse<MakeRootV2ResponseType> = await API.get(
      `${process.env.NEXT_PUBLIC_STRAPI_API_URL}${url}?_locale=${locale}&_publicationState=preview`,
    );

    return response.data;
  } catch (errors) {
    console.error(errors);
    throw new Error('Failed to fetch API');
  }
}

async function getPreviewableContent(
  id: string,
  contentType: string,
  locale: string,
) {
  try {
    const API = await ApiModule();
    const response: AxiosResponse<any[] | Record<string, any>> = await API.get(
      `${process.env.NEXT_PUBLIC_STRAPI_API_URL}/${contentType}?id_eq=${id}&_publicationState=preview&_locale=${locale}`,
    );

    if (
      !!response?.data &&
      Array.isArray(response?.data) &&
      response.data.length > 0
    ) {
      return response.data[0];
    }

    if (!!response?.data) {
      return response.data;
    }

    throw new Error('No data found on API');
  } catch (errors) {
    console.error(errors);
    throw new Error('Failed to fetch API');
  }
}

function fetchMasterJSON(id: string) {
  const url = `${process.env.NEXT_PUBLIC_API_URL}/v3/get_master_json_by_id?id=${id}&version=v2`;
  return ApiModule()
    .then(API => API.get(url))
    .then(res => {
      if (res && res.status === 200 && res.data != null) {
        return res.data;
      } else {
        throw new Error('could not access masterjson');
      }
    });
}

export {
  fetchTemplates,
  fetchConfig,
  getInvite,
  getHomepage,
  getMakePage,
  getMakePageById,
  fetchMasterJSON,
  getPreviewableContent,
  getPage,
};
